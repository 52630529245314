@import url(https://cdn.syncfusion.com/ej2/material.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}

input.form-control {
  border-radius: 15px;
}
button.btn, a.btn.btn-lg {
  border-radius: 15px;
}
label {
  margin: 0 5px;
}
h2 {
  color: #01437D;
}
.custom-select {
  border-radius: 15px 3px 3px 15px;
}
.form-control.cellphone {
  border-radius: 3px 15px 15px 3px;
}


footer {
  min-height: 120px;
  background: #F2F2F2;
  color: #7A7A7A;
}

.btn {
  transition: none;
  -webkit-transform: none;
          transform: none;
  margin-left: 10px;
}
.btn:focus {
  opacity: 0.7;
}

@media only screen and (min-width: 960px) {
  .navbar .navbar-nav .nav-link {
    padding: 1em 0.7em;
  }
  .navbar {
    padding: 10px 0px;
  }
  .navbar .navbar-brand {
    padding: 0 0.7em;
  }
}

.navbar-item {
  color: #0e3549!important;
  font-weight: lighter;
  font-size: small;
  text-transform: uppercase;
}
.navbar-item.down {
  display: flex;
}
.navbar-item.primary {
  color: white;
}
.navbar-item.outlined {
  color: #5ac0cc;
}
.navbar-item.canceled {
  color: white!important;
}
.btn.btn-danger:hover {
  border: 1px solid #dc3545;
  background: transparent;
  color: #dc3545!important;
}
.btn-outline-primary {
  border-color: #5ac0cc;
  color: #5ac0cc!important;
}
.btn-outline-primary:hover {
  background: #5ac0cc;
  border-color: #5ac0cc;
  color: white!important;
}
.btn.btn-primary:hover {
  background: white;
  color: #5ac0cc!important;
  transition: none;
  -webkit-transform: none;
          transform: none;
  border: 1px solid #5ac0cc;
}
.btn.btn-primary {
  color: white!important;
  border: 1px solid #5ac0cc;
}
.btn-outline-primary, .btn-primary, .btn-danger {
  border-radius: 50px;
}
@media screen and (max-width: 991px) {
  .btn.btn-outline-primary.navbar-item,
  .btn.btn-primary.navbar-item,
  .btn.btn-danger.navbar-item {
    background: none;
    color: #0e3549!important;
    width: 100%;
    border: none;
    text-align: left!important;
    margin: 0;
  }
  .btn.btn-outline-primary.navbar-item:hover,
  .btn.btn-primary.navbar-item:hover,
  .btn.btn-danger.navbar-item:hover {
    background: none;
    color: #5ac0cc!important;
    width: 100%;
    border: none;
    text-align: left!important;
    margin: 0;
  }
}
main {
  padding-top: 70px;
  min-height: calc(100vh - 120px);
  height: 100%;
  min-width: 100%;
}


.position-absolute {
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero {
  -webkit-filter: brightness(0.65);
          filter: brightness(0.65);
}
.hero-container {
  max-height: 500px;
  overflow: hidden;
}
.info {
  color: #5AC0CC;
}
.social-media {
  color: #01437D;
}
.info-row {
  background: #0E3549;
  min-height: 300px;
}
@media only screen and (min-width:768px) {
  .dashboard-card {
    min-width: 350px; 
    max-width: 350px;
  }
}
.col a {
  text-decoration: none;
}
.col a:hover {
  text-decoration: none;
}
.col a p {
  color: #0e3549;
}
a.modify.btn.btn-primary {
  border-radius: 15px;
}
.col a {
  text-decoration: none;
}
.col a:hover {
  text-decoration: none;
}
.col a p {
  color: #0e3549;
}
a.modify.btn.btn-primary {
  border-radius: 15px;
}
.form-control {
  border-radius: 15px;
}
button.btn, a.btn.btn-lg {
  border-radius: 15px;
}
label {
  margin: 0 5px;
}
h2 {
  color: #01437D;
}
.custom-select {
  border-radius: 15px 3px 3px 15px;
}
.form-control {
  border-radius: 15px;
  margin: 1px;
}
button.btn, a.btn.btn-lg {
  border-radius: 15px;
}
label {
  margin: 0 5px;
}
h2 {
  color: #01437D;
}
.custom-select {
  border-radius: 15px 3px 3px 15px;
}
a.modal-link {
  text-decoration: none;
  color: #0e3549;
}
.records-container{
  padding: 40px 40px;
  overflow: auto;
  /* max-height: 500px; */
  margin-bottom: 5px;
}

.dropdown-newMedicalRecord{
  background: lightgray;
  color: black
}
.form-control {
    border-radius: 15px;
  }
  button.btn, a.btn.btn-lg {
    border-radius: 15px;
  }
  label {
    margin: 0 5px;
  }
  h2 {
    color: #01437D;
  }
  .custom-select {
    border-radius: 15px 3px 3px 15px;
  }
.form-control {
    border-radius: 15px;
  }
  button.btn, a.btn.btn-lg {
    border-radius: 15px;
  }
  label {
    margin: 0 5px;
  }
  h2 {
    color: #01437D;
  }
  .custom-select {
    border-radius: 15px 3px 3px 15px;
  }
.table{
    margin: 30px 0;
    width: 100%;
}

thead{
    background-color: #5ac0cc;
    border: #5ac0cc;
    color: white;
}

.info-label{
    color: white;
    border-radius: 15px;
    padding: 5px;
    margin: 10px;
}

select{
    color: #5ac0cc;
    border-radius: 15px;
    padding: 8px;
    margin: 20px;
    border: #5ac0cc;
}
body {
  font-family: 'Work Sans', sans-serif;
  background: #F5F8FB;
}

.card, .form-control {
  border-radius: 15px;
}

.card-header {
  border-radius: 15px 15px 0 0!important;
  background: #5AC0CC;
}

.card-title{
  font-weight: bold;
  color:white;
}
.card-title2{
  font-weight: bold;
  color:black;
}

.admin-card-header {
  border-radius: 15px 0 0 15px;
}

.appointment-card {
  display: block;
  background-color: white;
  width: 100%;
  margin: 10px 0px;
  padding: 10px 30px;
  border: 1px solid #dddddd;
}

.appointment-card:hover {
  text-decoration:none;
  border: 1px solid #5ac0cc;
}

.dentist-card {
  background-color: white;
  margin-bottom: 10px;
  padding: 10px;
  border: 2px solid #f5f5f5;
  border-radius: 50px;
}

.dentist-card:hover {
  border: 2px solid #5ac0cc;
}

.dentist-list {
  margin: auto;
  width: 95%;
}



.btn-primary {
  background-color: #5ac0cc;
  border: #5ac0cc;
}

.btn-primary:hover {
  background-color: #3a7d85;
  border: #3a7d85;
}

.btn-secondary {
  background-color: #063348;
  border: #063348;
}

.btn-secondary:hover {
  background-color: #133e52;
  border: #133e52;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.bg-ion {
  background-color: #5ac0cc;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.hidden {
  display: none;
}

.jumbotron {
  background-size: cover;
  position: relative;
  overflow: hidden;
  height: 100vh;
  background-color: white;
}

/*.nav-link:hover {
  text-decoration: none;
  color: #61dafb;
  transition: all ease 0.3s;
}

.nav-link {
  text-decoration: none;
  color: #282c34;
  transition: all ease 0.3s;
}*/

.patient-content {
  margin-top: 3rem;
}

.card.dashboard-card {
  min-width: 350px;
  max-width: 350px;
}

@media only screen and (max-width: 767px) {
  .admin-card-header {
    border-radius: 15px 15px 0 0;
  }

}

@media only screen and (max-width: 599px) {
  .patient-content {
    margin-top: 5rem;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@media only screen and (max-width: 599px) {
  .dentist-list {
    margin: auto;
    width: 100%;
  }
  .navbar-icon {
    display:auto;
  }
  .navbar-login-link {
    padding-right: 0;
  }
  .navbar-register-link {
    padding-left: 0;
  }
  .navbar-login-register-logout {
    display:none;
  }
  .dentist-refresh-cancel {
    text-align: center;
    margin-right:0;
  }
  .dentalion-logo {
    height:50px;
  }
  .dentist-card-options {
    width:1.2em;
  }
  .landing-title {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  .appointment-right {
    width:100%;
  }
  .appointment-left {
    width:100%;
  }
  .appointment-right-content {
    width:100%;
  }
  .appointment-buttons {
    width:100%;
  }
  .appointment-cancel-modal {
    width:auto;
  }
}

@media only screen and (max-width: 400px) {
  .card.dashboard-card {
    min-width: 200px;
    max-width: 350px;
  }
}

@media only screen and (min-width: 600px) {
  .navbar-icon {
    display:none;
  }
  .navbar-login-link {
    padding-right: 1.5rem;
  }
  .navbar-register-link {
    padding-left: 1.5rem;
  }
  .navbar-login-register-logout {
    display:auto;
  }
  .dentist-refresh-cancel {
    text-align: right;
    margin-right: 1rem;
  }
  .dentalion-logo {
    height:50px;
  }
  .dentist-card-options {
    width:2em;
  }
  .landing-title {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  .appointment-right {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .appointment-right-content {
    justify-content: flex-end;
    text-align:right;
  }
  .appointment-left {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .appointment-row {
    display: flex;
    flex-wrap: wrap;
  }
  .appointment-buttons-div {
    display:flex;
    justify-content: flex-end;
  }

  .appointment-cancel-modal {
    width:35%;
  }
}

.dentist-card-options-container {
  flex: 0 0 25%;
    max-width: 25%;
}

@media only screen and (max-width: 1000px) {
  .dentist-card-email {
    display:none!important;
  }
  .dentist-card-options-container {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
}

@media only screen and (min-width: 600px) {
  div.content {
    margin-left: 200px;
    padding: 1px 16px;
    height: 1000px;
  }
}

@media only screen and (max-width: 1500px) {
  .appointment-date h1 {
    font-size: 25px;
  }
}


@media only screen and (min-width: 600px) {
  .nav-bar {
    display:flex;
    margin-right: 3rem;
    padding-right: 3rem;
  }
  .landing-body {
    width: 50%;
  }
}

@media only screen and (min-width: 993px) {
  .register-form {
    padding-right: 1.5rem;
    margin-bottom: 3rem;
    margin-right: 3rem;
    margin-top: 3rem;
  }
}

/* Checkout https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
/* Amazing page for flexbox */

/* All elements with class row */
.row {
  display: flex ;
  justify-content: space-evenly ;
  align-items: baseline ;
  flex-wrap: wrap ;
}

.rowCentered {
  display: flex ;
  justify-content: center ;
  align-items: baseline ;
  flex-wrap: wrap ;
}

.row > div:not(.toothOuter) {
  flex-grow: 1 ;
}

/* Padding 1 */
.padtop1 {
  padding-top: 1em ;
}
.padleft1 {
  padding-left: 1em ;
}
.padright1 {
  padding-right: 1em ;
}
.padbottom1 {
  padding-bottom: 1em ;
}
.padsides1 {
  padding-left: 1em ;
  padding-right: 1em ;
}
.padall1  {
  padding: 1em ;
}

/* Padding 0.5 */
.padtop05 {
  padding-top: 0.5em ;
}
.padleft05 {
  padding-left: 0.5em ;
}
.padright05 {
  padding-right: 0.5em ;
}
.padbottom05 {
  padding-bottom: 0.5em ;
}
.padsides05 {
  padding-left: 0.5em ;
  padding-right: 0.5em ;
}
.padall05  {
  padding: 0.5em ;
}

/* Width to fill parent */
.w100 {
  width: 100% ;
}
.w50 {
  width: 50% ;
}
.w75 {
  width: 75% ;
}
.w25 {
  min-width: 27% ;
}
.w3   {
  max-width: 5% ;
  min-width: 5% ;
}

/* Margins */
.martop05 {
  margin-top: 0.5em ;
}
.marleft05 {
  margin-left: 0.5em ;
}
.marright05 {
  margin-right: 0.5em ;
}
.marbottom05 {
  margin-bottom: 0.5em ;
}
.marsides05 {
  margin-left: 0.5em ;
  margin-right: 0.5em ;
}
.marall05  {
  margin: 0.5em ;
}
.marsides1 {
  margin-left: 1em ;
  margin-right: 1em ;
}

.marall1 {
  margin: 1em ;
}

.marbottom1 {
  margin-bottom: 1em ;
}

.marsides5 {
  margin-left: 5em ;
  margin-right: 5em ;
}

.marright1 {
  margin-right: 1.4em ;
}

.marsides01 {
  margin-left: 0.1em ;
  margin-right: 0.1em ;
}

.marsides02 {
  margin-left: 0.2em ;
  margin-right: 0.2em ;
}

.marsides03 {
  margin-left: 0.3em ;
  margin-right: 0.3em ;
}

.marsides2 {
  margin-left: 2em ;
  margin-right: 2em ;
}

.martop2 {
  margin-top: 2em ;
}

.marbottom2 {
  margin-bottom: 2em ;
}

.martop1 {
  margin-top: 1em ;
}

.marbottom1 {
  margin-bottom: 1em ;
}

/* Shadow to boxes */
.shadow {
  box-shadow: 3px 3px 0 0 black ;
}
.size5 {
  font-size: larger ;
}

/* Css for the tooths in Clinical Examn */
.toothOuter  {
  display: flex ;
  flex-direction: column ;
  align-items: center ;
  height: 50px ;
  width: 50px ;
  padding-bottom: 5% ;
}

.toothInner {
  height: 0 ;
  width: 85% ;
  padding-bottom: 85% ;
  border-style: solid ;
  border-width: 0.05em ;
  border-color: black ;
}

.toothMostInner {
  margin: 26% ;
  height: 0 ;
  width: 48% ;
  padding-bottom: 48% ;
  border-style: solid ;
  border-width: 0.05em ;
  border-color: black ;
  background-color: white;
}

.toothState {
  font-size: 90% ;
}

.crossed {
  background:
      linear-gradient(to top left,
          rgba(0,0,0,0) 0%,
          rgba(0,0,0,0) calc(50% - 0.4px),
          rgba(0,0,0,1) 50%,
          rgba(0,0,0,0) calc(50% + 0.4px),
          rgba(0,0,0,0) 100%),
      linear-gradient(to top right,
          rgba(0,0,0,0) 0%,
          rgba(0,0,0,0) calc(50% - 0.4px),
          rgba(0,0,0,1) 50%,
          rgba(0,0,0,0) calc(50% + 0.4px),
          rgba(0,0,0,0) 100%);
}

.endRow {
  display: flex ;
  flex-direction: row-reverse ;
}

.flex {
  display: flex ;
}

.flex-row {
  flex-direction: row ;
}

.flex-column  {
  flex-direction: column ;
}

.flex-wrap {
  flex-wrap: wrap ;
}

.flex-align-items-end {
  align-items: flex-end ;
}

.flex-align-items-start {
  align-items: flex-start ;
}

.flex-just-space-even  {
  justify-content: space-evenly ;
}

.flex-just-space-between  {
  justify-content: space-between ;
}

.flex-just-space-center  {
  justify-content: center ;
}

.flex-just-space-start  {
  justify-content: flex-start ;
}

.flex-just-space-end {
  justify-content: flex-end ;
}

.flex-align-content-stretch {
  align-content: stretch ;
}

.flex-align-items-base {
  align-items: baseline ;
}

.flex-grow  {
  flex-grow: 1 ;
}

.flex-grow-2 {
  flex-grow: 2 ;
}

.disable {
  pointer-events: none;
}

.disable input {
 background-color: #f8f8f8;
}
 .disable textarea {
  background-color: #f8f8f8;
 }

@media (max-width: 991px) {
  .hide-on-narrow {
    display: none ;
  }

  .show-narrow  {
    display: block ;
  }
}

@media (max-width: 990px) {
  .hide-on-more-narrow {
    display: none ;
  }

  .show-narrow  {
    display: block ;
  }
}

.page-title{
  font-weight: bold;
  padding-top: 2%;
  color: #5AC0CC;
}

