@import url("https://cdn.syncfusion.com/ej2/material.css");

.table{
    margin: 30px 0;
    width: 100%;
}

thead{
    background-color: #5ac0cc;
    border: #5ac0cc;
    color: white;
}

.info-label{
    color: white;
    border-radius: 15px;
    padding: 5px;
    margin: 10px;
}

select{
    color: #5ac0cc;
    border-radius: 15px;
    padding: 8px;
    margin: 20px;
    border: #5ac0cc;
}