.records-container{
  padding: 40px 40px;
  overflow: auto;
  /* max-height: 500px; */
  margin-bottom: 5px;
}

.dropdown-newMedicalRecord{
  background: lightgray;
  color: black
}