.btn {
  transition: none;
  transform: none;
  margin-left: 10px;
}
.btn:focus {
  opacity: 0.7;
}

@media only screen and (min-width: 960px) {
  .navbar .navbar-nav .nav-link {
    padding: 1em 0.7em;
  }
  .navbar {
    padding: 10px 0px;
  }
  .navbar .navbar-brand {
    padding: 0 0.7em;
  }
}

.navbar-item {
  color: #0e3549!important;
  font-weight: lighter;
  font-size: small;
  text-transform: uppercase;
}
.navbar-item.down {
  display: flex;
}
.navbar-item.primary {
  color: white;
}
.navbar-item.outlined {
  color: #5ac0cc;
}
.navbar-item.canceled {
  color: white!important;
}
.btn.btn-danger:hover {
  border: 1px solid #dc3545;
  background: transparent;
  color: #dc3545!important;
}
.btn-outline-primary {
  border-color: #5ac0cc;
  color: #5ac0cc!important;
}
.btn-outline-primary:hover {
  background: #5ac0cc;
  border-color: #5ac0cc;
  color: white!important;
}
.btn.btn-primary:hover {
  background: white;
  color: #5ac0cc!important;
  transition: none;
  transform: none;
  border: 1px solid #5ac0cc;
}
.btn.btn-primary {
  color: white!important;
  border: 1px solid #5ac0cc;
}
.btn-outline-primary, .btn-primary, .btn-danger {
  border-radius: 50px;
}
@media screen and (max-width: 991px) {
  .btn.btn-outline-primary.navbar-item,
  .btn.btn-primary.navbar-item,
  .btn.btn-danger.navbar-item {
    background: none;
    color: #0e3549!important;
    width: 100%;
    border: none;
    text-align: left!important;
    margin: 0;
  }
  .btn.btn-outline-primary.navbar-item:hover,
  .btn.btn-primary.navbar-item:hover,
  .btn.btn-danger.navbar-item:hover {
    background: none;
    color: #5ac0cc!important;
    width: 100%;
    border: none;
    text-align: left!important;
    margin: 0;
  }
}