.form-control {
  border-radius: 15px;
  margin: 1px;
}
button.btn, a.btn.btn-lg {
  border-radius: 15px;
}
label {
  margin: 0 5px;
}
h2 {
  color: #01437D;
}
.custom-select {
  border-radius: 15px 3px 3px 15px;
}