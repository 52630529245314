.col a {
  text-decoration: none;
}
.col a:hover {
  text-decoration: none;
}
.col a p {
  color: #0e3549;
}
a.modify.btn.btn-primary {
  border-radius: 15px;
}