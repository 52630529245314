
.position-absolute {
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero {
  filter: brightness(0.65);
}
.hero-container {
  max-height: 500px;
  overflow: hidden;
}
.info {
  color: #5AC0CC;
}
.social-media {
  color: #01437D;
}
.info-row {
  background: #0E3549;
  min-height: 300px;
}